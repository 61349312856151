<template>
    <div>
      <PageLoader :storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #fff">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap justify-start pa-5>
                <v-flex xs12>
                  <span class="heading">RELEASED OPERATION</span>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap justify-start>
                    <v-flex xs12>
                      <v-card flat>
                        <v-layout wrap justify-center pa-2>
                          <v-flex xs12 sm12 lg12>
                            <v-layout wrap justify-start pt-10>
                              <v-flex xs12>
                                <span class="subheading">Report Details</span>
                              </v-flex>
                            </v-layout>
                            <v-layout wrap justify-start>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="
                                  data &&
                                  data.reportId &&
                                  data.reportId.description
                                "
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Description</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ data.reportId.description }}
                                    <span v-if="!data.reportId.description"
                                      >-</span
                                    >
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="
                                  data && data.reportId && data.reportId.snake
                                "
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Snake</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ data.reportId.snake.name }}
                                    <span v-if="!data.reportId.snake.name"
                                      >-</span
                                    >
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="
                                  data && data.reportId && data.reportId.createdAt
                                "
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Created At</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{formatDate(data.reportId.createdAt)}}
                                    <span v-if="!data.reportId.createdAt">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="data && data.reportId && data.reportId.user"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Reported By</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ data.reportId.user.name }} -
                                    {{ data.reportId.user.phone }}
                                    <span v-if="!data.reportId.user">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="
                                  data && data.reportId && data.reportId.situation
                                "
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Situation</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ data.reportId.situation }}
                                    <span v-if="!data.reportId.situation">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="data && divisionname"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Division Name</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ divisionname }}
                                    <span v-if="!divisionname">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                            </v-layout>
                            <v-layout wrap justify-start pt-10>
                              <v-flex xs12>
                                <span class="subheading">Rescue Details</span>
                              </v-flex>
                            </v-layout>
                            <v-layout wrap justify-start>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="data && data.rescuer && data.rescuer.name"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Rescuer</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ data.rescuer.name }} -
                                    {{ data.rescuer.phone }}
                                    <span v-if="!data.rescuer.name">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="
                                  data && data.rescue && data.rescue.rescuedAt
                                "
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Rescued At</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ formatDate(data.rescue.rescuedAt)}}
                                    <span v-if="!data.rescue.rescuedAt">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="data && data.rescue && data.rescue.locality"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Rescued from</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ data.rescue.locality }}
                                    <span v-if="!data.rescue.locality">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="data && data.rescue"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Laid Eggs</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    <span v-if="data.rescue.laidEggs === true"
                                      >Yes</span
                                    >
                                    <span v-if="data.rescue.laidEggs === false"
                                      >No</span
                                    >
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="data && data.rescue"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Gravid</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    <span v-if="data.rescue.gravid === true"
                                      >Yes</span
                                    >
                                    <span v-if="data.rescue.gravid === false"
                                      >No</span
                                    >
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="data && data.rescue && data.rescue.district"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">District</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ data.rescue.district }}
                                    <span v-if="!data.rescue.district">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="data && data.rescue && data.rescue.quantity"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Quantity</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ data.rescue.quantity }}
                                    <span v-if="!data.rescue.quantity">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="data && data.rescue && data.rescue.sex"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Sex</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ data.rescue.sex }}
                                    <span v-if="!data.rescue.sex">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="
                                  data && data.rescue && data.rescue.difficulties
                                "
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Rescue Difficulties</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ data.rescue.difficulties }}
                                    <span v-if="!data.rescue.difficulties"
                                      >-</span
                                    >
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="
                                  data && data.rescue && data.rescue.delayReasons
                                "
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Delay Reasons</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ data.rescue.delayReasons }}
                                    <span v-if="!data.rescue.delayReasons"
                                      >-</span
                                    >
                                  </span>
                                </v-flex>
                              </v-flex>
                            </v-layout>
                            <v-layout wrap justify-start pt-10 v-if="data && data.release && data.release.location.length > 0">
                              <v-flex xs12>
                                <span class="subheading">Released  Details</span>
                              </v-flex>
                            </v-layout>
                            <v-layout wrap justify-start v-if="data && data.release && data.release.location.length > 0">
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="data && data.release && data.release.releasedAt"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Released Date</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{formatDate(data.release.releasedAt)}}
                                    <span v-if="!data.release.releasedAt">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="data && data.release"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Released or Not?</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    <span v-if="data.release.releasedOrNot == true">Yes</span>
                                    <span v-else>No</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="data && data.release && data.release.locality"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Released from</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{data.release.locality}}
                                    <span v-if="!data.release.locality">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="data && data.release && data.release.snakeHealth"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Snake Health</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{data.release.snakeHealth}}
                                    <span v-if="!data.release.snakeHealth">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="data && data.release"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Snake Mishaps</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    <span v-if="data.release.mishaps == true">No Changes</span>
                                    <span v-else>Yes</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="data && data.release && data.release.district"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">District</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{data.release.district}}
                                    <span v-if="!data.release.district">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                            </v-layout>
                            <v-layout wrap justify-start pt-10 v-if="reportedimages && reportedimages.length > 0">
                              <v-flex xs12>
                                <span class="subheading">Reported Images</span>
                              </v-flex>
                              <v-flex xs12>
                                <v-layout wrap justify-start>
                                  <v-flex
                                    xs12
                                    v-for="(item, index) in reportedimages"
                                    :key="index"
                                    sm4
                                    md3
                                    lg2
                                    v-viewer="{ movable: false }"
                                    pa-1
                                  >
                                    <img
                                      contain
                                      height="200px"
                                      width="100%"
                                      :src="mediaURL + item"
                                      fill-height
                                      class="clickable"
                                    />
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                            <v-layout wrap justify-start pt-10 v-if="rescuedimages && rescuedimages.length > 0">
                              <v-flex xs12>
                                <span class="subheading">Rescued Images</span>
                              </v-flex>
                              <v-flex xs12>
                                <v-layout wrap justify-start>
                                  <v-flex
                                    xs12
                                    v-for="(item, index) in rescuedimages"
                                    :key="index"
                                    sm4
                                    md3
                                    lg2
                                    v-viewer="{ movable: false }"
                                    pa-1
                                  >
                                    <img
                                      contain
                                      height="200px"
                                      width="100%"
                                      :src="mediaURL + item"
                                      fill-height
                                      class="clickable"
                                    />
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                            <v-layout wrap justify-start pt-10 v-if="releasedimages && releasedimages.length > 0">
                              <v-flex xs12>
                                <span class="subheading">Released Images</span>
                              </v-flex>
                              <v-flex xs12>
                                <v-layout wrap justify-start>
                                  <v-flex
                                    xs12
                                    v-for="(item, index) in releasedimages"
                                    :key="index"
                                    sm4
                                    md3
                                    lg2
                                    v-viewer="{ movable: false }"
                                    pa-1
                                  >
                                    <img
                                      contain
                                      height="200px"
                                      width="100%"
                                      :src="mediaURL + item"
                                      fill-height
                                      class="clickable"
                                    />
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                            <v-layout wrap justify-start pt-10>
                              <v-flex xs12>
                                <span class="subheading">Location</span>
                              </v-flex>
                              <v-flex xs12>
                                <v-layout wrap justify-center>
                                  <v-flex xs12>
                                    <div id="map"></div>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 pt-lg-5 pl-2>
                  <v-layout wrap justify-start>
                    <v-flex xs12 sm4 md4 lg4>
                      <v-btn
                        depressed
                        color="success"
                        @click="approveoperationDialog = true"
                      >
                        <span>Approve Operation</span>
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4>
                      <v-btn
                        depressed
                        color="warning"
                        @click="approveDialog = true"
                      >
                        <span>Close Operation</span>
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-sm-2 pl-md-2>
                      <v-btn depressed color="error" @click="rejectDialog = true">
                        <span>Delete Operation</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-dialog v-model="approveDialog" max-width="500">
        <v-card>
          <v-card-title class="heading">Close Operation</v-card-title>
          <v-card-text>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12 class="subheading">
                    <span>Remarks</span>
                    <v-text-field outlined dense v-model="closeremarks">
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="approveDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="closeoperation()"
              class="itemValue"
            >
              <span style="color: #fff">Close</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="approveoperationDialog" max-width="500">
        <v-card>
          <v-card-title class="heading">Approve Operation</v-card-title>
          <v-card-text>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12 class="subheading">
                    <span>Remarks</span>
                    <v-text-field outlined dense v-model="approveremarks">
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="approveoperationDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="approveoperation()"
              class="itemValue"
            >
              <span style="color: #fff">Approve</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="rejectDialog" max-width="500">
        <v-card>
          <v-card-title class="heading">Confirmation</v-card-title>
          <v-card-text
            >Are you sure you want to delete the operation?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="rejectDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="deleteoperation()"
              class="itemValue"
            >
              <span style="color: #fff">Submit</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
    <script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBpukNl1L1qGqEwad87A1-MkZj8H_bKDZM&libraries=visualization,drawing"></script>
    <script>
  var marker;
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        data: [],
        reportedimages: [],
        rescuedimages:[],
        releasedimages:[],
        map: "",
        mapData: {},
        location: [],
        approveDialog: false,
        closeremarks: "",
        rejectDialog: false,
        divisionname: "",
        photoArray: [],
        approveremarks:"",
        idByAdmin: "",
        distance: "",
        approveoperationDialog: false,
      };
    },
    beforeMount() {
      this.getData();
    },
    //   mounted() {
    //   this.$nextTick(() => {
    //     this.initMap();
    //   });
    // },
    methods: {
      initMap() {
        var vm = this;
        var mapCanvas = document.getElementById("map");
  
        // Set default map options
        var mapOptions = {
          zoom: 10,
          mapTypeId: google.maps.MapTypeId.TERRAIN,
        };
  
        // Create a new map
        vm.map = new google.maps.Map(mapCanvas, mapOptions);
  
        // Plot the reported location if available
        if (vm.location && vm.location.length === 2) {
          var reportedLat = vm.location[1]; // Latitude of reported location
          var reportedLon = vm.location[0]; // Longitude of reported location
  
          mapOptions.center = new google.maps.LatLng(reportedLat, reportedLon);
          vm.map.setOptions(mapOptions);
  
          // Create a marker for the reported location
          var reportedMarker = new google.maps.Marker({
            map: vm.map,
            position: new google.maps.LatLng(reportedLat, reportedLon),
            title: "Reported Location",
          });
  
          // Create an InfoWindow for the reported location
          var reportedInfoWindow = new google.maps.InfoWindow({
            content: `
    <div style="width: 200px; height: 50px;">
      <h3 style="margin: 0;">Reported Location</h3>
    </div>
  `,
          });
  
          // Add a click event listener to the reported marker
          reportedMarker.addListener("click", function () {
            reportedInfoWindow.open(vm.map, reportedMarker);
          });
        }
  
        // Plot the rescued location if available
        if (
          vm.data.rescue &&
          vm.data.rescue.location &&
          vm.data.rescue.location.length === 2
        ) {
          var rescueLat = vm.data.rescue.location[1]; // Latitude of rescued location
          var rescueLon = vm.data.rescue.location[0]; // Longitude of rescued location
  
          // Create a marker for the rescued location
          var rescueMarker = new google.maps.Marker({
            map: vm.map,
            position: new google.maps.LatLng(rescueLat, rescueLon),
            title: "Rescued Location",
          });
  
          // Create an InfoWindow for the rescued location
          var rescueInfoWindow = new google.maps.InfoWindow({
            content: `
    <div style="width: 200px; height: 50px;">
      <h3 style="margin: 0;">Rescued Location</h3>
    </div>
  `,
          });
  
          // Add a click event listener to the rescued marker
          rescueMarker.addListener("click", function () {
            rescueInfoWindow.open(vm.map, rescueMarker);
          });
        }


        if (
          vm.data.release &&
          vm.data.release.location &&
          vm.data.release.location.length === 2
        ) {
          var releaseLat = vm.data.release.location[1]; // Latitude of released location
          var releaseLon = vm.data.release.location[0]; // Longitude of released location
  
          // Create a marker for the released location
          var releaseMarker = new google.maps.Marker({
            map: vm.map,
            position: new google.maps.LatLng(releaseLat, releaseLon),
            title: "Released Location",
          });
  
          // Create an InfoWindow for the released location
          var releaseInfoWindow = new google.maps.InfoWindow({
            content: `
    <div style="width: 200px; height: 50px;">
      <h3 style="margin: 0;">Released Location</h3>
    </div>
  `,
          });
  
          // Add a click event listener to the released marker
          releaseMarker.addListener("click", function () {
            releaseInfoWindow.open(vm.map, releaseMarker);
          });
        }
      },
      formatDate(date) {
    if (!date) return '-';
    const [year, month, day] = date.slice(0, 10).split('-');
    return `${day}-${month}-${year}`;
  },
      getData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/operation/get",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            id: this.$route.query.id,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.data = response.data.data;
                this.divisionname = this.data.division[0].divisionName;
                this.reportedimages = response.data.reportedimages;
                this.rescuedimages = response.data.rescuedimages;
                this.releasedimages = response.data.releasedimages;
                this.location = this.data.reportId.location;
                this.photoArray = this.data.photographs.map(
                  (photoObj) => photoObj.photo
                );
                this.appLoading = false;
                // this.msg = response.data.msg;
                // this.showSnackBar = true;
                this.initMap();
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
      closeoperation() {
        if (!this.closeremarks) {
          this.msg = "Please enter remarks.";
          this.showSnackBar = true;
          return;
        }
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/operation/closereport",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            id: this.$route.query.id,
            remarks: this.closeremarks,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.msg = "Report Closed Successfully.";
                this.showSnackBar = true;
                this.appLoading = false;
                this.approveDialog = false;
                this.$router.push({ path: "/releasedoperations" });
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      
      approveoperation() {
        if (!this.approveremarks) {
          this.msg = "Please enter remarks.";
          this.showSnackBar = true;
          return;
        }
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/operation/approve",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            id: this.$route.query.id,
            remarks: this.approveremarks,
            idByAdmin: this.idByAdmin,
            distance:this.distance,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.msg = "Approved Successfully.";
                this.showSnackBar = true;
                this.appLoading = false;
                this.approveoperationDialog = false;
                this.$router.push({ path: "/releasedoperations" });
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      deleteoperation() {
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/operation/delete",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            id: this.$route.query.id,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.msg = "Operation Deleted Successfully.";
                this.showSnackBar = true;
                this.appLoading = false;
                this.rejectDialog = false;
                this.$router.push({ path: "/releasedoperations" });
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
          <style>
  .heading {
    font-family: poppinsregular;
    font-size: 20px;
    font-weight: 900;
  }
  .subheading {
    font-family: poppinsregular;
    font-size: 15px;
    font-weight: 500;
  }
  #map {
    height: 300px !important;
    width: 100%;
  }
  </style>